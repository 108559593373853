import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { EMRAPageInfo as State, NodeInfo, SimInfo, Address, Board, NodeInfoFunctions, NodeAvailabilityCheck, MeterAvailabilityCheck } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        detailNode: null,
        address: null,
        alias: "",
        comment: "",
        relatedUsers: [],
        systemType: 0,
        placementDate: "",
        simInfo: null,
        board: null,
        nodeAvailabilityCheck: null,
        meterAvailabilityChecks: [],
        countries: [],
        emraNames: [],
        sims: [],
        preloaded: false,
        isInPresentationMode: false
    },
    mutations: {
        ADD_OR_REMOVE_SIM(state : State, sim: any): void {
            if (!sim) return
            const simId = sim.id
            const simExistsAtIndex = state.sims.findIndex(s => s.id == simId)
            if (simExistsAtIndex >= 0) {
                state.sims.splice(simExistsAtIndex, 1)
            } else {
                state.sims.push(sim)
            }
        },
        SET_NODE_INFO(state : State, node: NodeInfo): void {
            state.detailNode = node
            state.preloaded = true
        },
        SET_ADDRESS(state: State, address: Address): void {
            state.address = address
        },
        SET_ALIAS(state: State, alias: string): void {
            state.alias = alias

            if (state.detailNode)
                state.detailNode.alias = alias
        },
        SET_EMRA_SERIAL_ID(state: State, emraSerialId: string): void {
            if (state.detailNode)
                state.detailNode.emraSerialId = emraSerialId
        },
        SET_COMMENT(state: State, comment: string): void {
            state.comment = comment
        },
        SET_PREPARATION_STEP(state: State, preparationStepUpdate: any): void {
            state.detailNode.readyForPlacement = preparationStepUpdate.readyForPlacement
            state.detailNode.prepared = preparationStepUpdate.prepared
            state.detailNode.toBePrepared = preparationStepUpdate.toBePrepared
            state.detailNode.outOfUse = preparationStepUpdate.outOfUse
        },
        // SET_RELATED_USERS(state : State, relations: any[]): void {
        //     state.relatedUsers = relations
        // },
        SET_SYSTEM_TYPE(state: State, systemType: number): void {
            state.systemType = systemType
        },
        SET_PLACEMENT_DATE(state: State, placementDate: string): void {
            state.placementDate = placementDate
        },
        SET_SIM_INFO(state: State, simInfo: SimInfo): void {
            state.simInfo = simInfo
        },
        SET_BOARD(state: State, board: Board): void {
            state.board = board
        },
        SET_NODE_AVAILABILITY_CHECK(state: State, nodeAvailabilityCheck: NodeAvailabilityCheck) {
            state.nodeAvailabilityCheck = nodeAvailabilityCheck
        },
        SET_METER_AVAILABILITY_CHECKS(state: State, meterAvailabilityChecks: MeterAvailabilityCheck[]) {
            state.meterAvailabilityChecks = meterAvailabilityChecks
        },
        SET_COUNTRIES(state: State, countries: any[]): void {
            state.countries = countries;
        },
        SET_EMRA_NAMES(state: State, emraNames: string[]): void {
            state.emraNames = emraNames;
        },
        SET_SIMS(state: State, sims: any[]): void {
            state.sims = sims;
        },
        // DELETE_USER_RELATION(state: State, nodeAndUserId: any): void {
        //     const index = state.relatedUsers.findIndex((r: any) => r.nodeId == nodeAndUserId.nodeId && r.user.id == nodeAndUserId.userId)
        //     if (index > -1) {
        //         state.relatedUsers.splice(index, 1)
        //     }
        // },
        SET_PRESENTATION_MODE(state: State, presentationMode: boolean): void {
            state.isInPresentationMode = presentationMode
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            if (!state.detailNode) return false
            return await http.get(`/emra-page-info?nodeId=${state.detailNode?.id}`)
                // eslint-disable-next-line 
                .then((response: any) => {
                    commit('SET_ADDRESS', response.data.address)
                    commit('SET_ALIAS', response.data.alias)
                    commit('SET_COMMENT', response.data.comment)
                    // commit('SET_RELATED_USERS', response.data.relatedUsers)
                    commit('SET_SYSTEM_TYPE', response.data.systemType)
                    commit('SET_PLACEMENT_DATE', response.data.placementDate)
                    commit('SET_SIM_INFO', response.data.simInfo)
                    commit('SET_BOARD', response.data.board)
                    commit('SET_NODE_AVAILABILITY_CHECK', response.data.nodeAvailabilityCheck)
                    commit('SET_METER_AVAILABILITY_CHECKS', response.data.meterAvailabilityChecks)
                    commit('SET_COUNTRIES', response.data.countries)
                    commit('SET_EMRA_NAMES', response.data.emraNames)
                    commit('SET_SIMS', response.data.sims)
                    return true
                })
                .catch((error : Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateNodeAliasAndSerialId({ commit } : Commit, nodeIdWithAliasAndSerialId: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-alias-and-serial-id', nodeIdWithAliasAndSerialId)
                .then((response: any) => {
                    commit('SET_ALIAS', response.data.alias)
                    commit('SET_EMRA_SERIAL_ID', response.data.emraSerialId)
                    return response.data
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateNodeSimId({ commit } : Commit, nodeIdWithSimId: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-sim-id', nodeIdWithSimId)
                .then((response: any) => {
                    return response.data
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateNodePlacementDate({ commit } : Commit, nodeIdWithPlacementDate: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-placement-date', nodeIdWithPlacementDate)
                .then((response: any) => {
                    commit('SET_PLACEMENT_DATE', response.data.placementDate)
                    return response.data
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateAddress({ commit } : Commit, address: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-address', address)
                .then((response: any) => {
                    commit('SET_ADDRESS', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateComment({ commit } : Commit, comment: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-comment', comment)
                .then((response: any) => {
                    commit('SET_COMMENT', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async setPreparationStep({ commit } : Commit, preparationStepUpdate: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-preparation-step', preparationStepUpdate)
                .then((response: any) => {
                    commit('SET_PREPARATION_STEP', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        }
    },
    getters: {
        DetailNode: (state: State) : NodeInfo|null => !state.isInPresentationMode
            ? state.detailNode
            : NodeInfoFunctions.hideSensitiveInfoFromNodeInfo(state.detailNode),
        Address: (state: State) : Address|null => !state.isInPresentationMode ? state.address : null,
        Alias: (state: State) : string => !state.isInPresentationMode ? state.alias : "",
        Comment: (state: State) : string => state.comment,
        // RelatedUsers: (state: State) : any[] => state.relatedUsers,
        SystemType: (state: State) : number => state.systemType,
        PlacementDate: (state: State) : string => state.placementDate,
        SimInfo: (state: State) : SimInfo|null => !state.isInPresentationMode ? state.simInfo : null,
        Board: (state: State) : Board => state.board,
        NodeAvailabilityCheck: (state: State) : NodeAvailabilityCheck => state.nodeAvailabilityCheck,
        MeterAvailabilityChecks: (state: State) : MeterAvailabilityCheck[] => state.meterAvailabilityChecks,
        Countries: (state: State) : any[] => state.countries,
        EmraNames: (state: State) : string[] => !state.isInPresentationMode
            ? state.emraNames
            : state.emraNames.map(_ => "EMRA"),
        Sims: (state: State) : any[] => state.sims,
        IsPreloaded: (state: State) : boolean => state.preloaded,
        IsInPresentationMode: (state: State) : boolean => state.isInPresentationMode
    }
}

